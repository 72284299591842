var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "terms-of-service py-12" },
    [
      _c("v-container", [
        _c("div", { staticClass: "wrap" }, [
          _c("div", { staticClass: "page-container" }, [
            _c("div", { staticClass: "content-width-limit" }, [
              _c(
                "div",
                { staticClass: "page-header" },
                [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.parent.$t("termsOfService.title"))),
                  ]),
                  _c("h6", [
                    _vm._v(_vm._s(_vm.parent.$t("termsOfService.date"))),
                  ]),
                  _c("i18n", {
                    staticClass: "my-12",
                    attrs: { path: "termsOfService.welcome", tag: "p" },
                    scopedSlots: _vm._u([
                      {
                        key: "tos",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                '"' +
                                  _vm._s(_vm.parent.$t("termsOfService.tos")) +
                                  '"'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "mew",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                '"' +
                                  _vm._s(_vm.parent.$t("termsOfService.mew")) +
                                  '"'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "site",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                '"' +
                                  _vm._s(_vm.parent.$t("termsOfService.site")) +
                                  '"'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "services",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                '"' +
                                  _vm._s(
                                    _vm.parent.$t("termsOfService.services")
                                  ) +
                                  '"'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("h3", [
                    _vm._v(
                      " " + _vm._s(_vm.parent.$t("termsOfService.intro")) + " "
                    ),
                  ]),
                  _c("i18n", {
                    attrs: { path: "termsOfService.intro-p1", tag: "p" },
                    scopedSlots: _vm._u([
                      {
                        key: "privPol",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.myetherwallet.com/privacy-policy",
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.parent.$t(
                                      "termsOfService.privacy-policy"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "agreement",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                '"' +
                                  _vm._s(
                                    _vm.parent.$t("termsOfService.agreement")
                                  ) +
                                  '"'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parent.$t("termsOfService.intro-p2")) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parent.$t("termsOfService.intro-p3")) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("h3", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-1-p"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-2"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-2-p-1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-2-p-2"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parent.$t(
                            "termsOfService.services-eligibility-title-2-p-3"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("i18n", {
                    attrs: {
                      path: "termsOfService.services-eligibility-title-2-p-4",
                      tag: "p",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "article1",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.parent.$store.getters[
                                    "article/getArticle"
                                  ]("mew-introduction"),
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _vm._v(
                                  '"' +
                                    _vm._s(
                                      _vm.parent.$t("termsOfService.article1")
                                    ) +
                                    '"'
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "article2",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.parent.$store.getters[
                                    "article/getArticle"
                                  ]("avoid-phishing-scams"),
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _vm._v(
                                  "“" +
                                    _vm._s(
                                      _vm.parent.$t("termsOfService.article2")
                                    ) +
                                    "”"
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-1-p"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2-p-1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2-p-2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2-p-3"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2-p-4"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-2-p-5"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-3"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.rights-and-restrictions-title-3-p"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.parent.$t("termsOfService.privacy-title"))
                    ),
                  ]),
                  _c("i18n", {
                    attrs: { path: "termsOfService.privacy-title-p", tag: "p" },
                    scopedSlots: _vm._u([
                      {
                        key: "link",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://myetherwallet.com/privacy-policy",
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _vm._v(
                                  '"' +
                                    _vm._s(
                                      _vm.parent.$t("termsOfService.link")
                                    ) +
                                    '"'
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.parent.$t("termsOfService.third-party-title"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.third-party-title-p")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.subscription-plans-title")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.subscription-plans-p1")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.subscription-plans-p2")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.subscription-plans-p3")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.subscription-plans-p4")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-free-trial-title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-free-trial-title-p1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-free-trial-title-p2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-free-trial-title-p3"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-fee-changes-title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-fee-changes-title-p1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-fee-changes-title-p2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-refund-title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.subscription-refund-title-p1"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.parent.$t("termsOfService.disclaimer-title"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.disclaimer-titlep-1")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.disclaimer-titlep-2")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.disclaimer-titlep-3")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.disclaimer-titlep-4")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.disclaimer-titlep-5")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.parent.$t("termsOfService.indemnity-title"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.indemnity-title-p")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.parent.$t("termsOfService.assignment-title"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.assignment-title-p")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.parent.$t("termsOfService.agreement-waiver-title")
                    )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-0"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-1"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-2"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-3"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-4"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-5"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-6"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-7"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-8"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-9"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-10"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-11"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t(
                          "termsOfService.agreement-waiver-title-p-12"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.parent.$t("termsOfService.governing-law-title"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.governing-law-title-p-1")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.governing-law-title-p-2")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.parent.$t("termsOfService.entire-agreement-title")
                    )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parent.$t("termsOfService.entire-agreement-title-p")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.parent.$t("termsOfService.contact-info-title"))
                    ),
                  ]),
                  _c("i18n", {
                    attrs: {
                      path: "termsOfService.contact-info-title-p",
                      tag: "p",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "suppEmail",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:support@xinfin.org",
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [_vm._v(" support@xinfin.org")]
                            ),
                            _vm._v(". "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }